import { DependencyContainer } from '../../DependencyContainer';

export default class TableauService {
  constructor(private readonly factory: DependencyContainer) {}

  async getTableauToken(): Promise<string> {
    try {
      const response = await this.factory.tableauClient.fetchTableauToken();
      return response.data?.token || null;
    } catch (error) {
      console.log('Error fetching JWT: ', error);
      throw error;
    }
  }
}
