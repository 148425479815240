import { useEffect, useState } from 'react';
import { DependencyContainer } from '../DependencyContainer';
import { jwtDecode } from 'jwt-decode';

const useTableauToken = (expiryThreshold = 300) => {
  // Default threshold set to 5 minutes
  const [token, setToken] = useState(sessionStorage.getItem('tableauToken'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<null | Error>(null);

  const { tableauService } = new DependencyContainer();

  const checkTokenValidity = (token: string) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      // If the token will expire within the next 'expiryThreshold' seconds
      if ((decoded as { exp: number }).exp < currentTime + expiryThreshold) {
        return false;
      }

      return true;
    } catch (err) {
      // TODO: Handle decode error !!
      return false;
    }
  };

  const fetchToken = async () => {
    setLoading(true);
    setError(null);
    try {
      const newToken = await tableauService.getTableauToken();
      sessionStorage.setItem('tableauToken', newToken);
      setToken(newToken);
    } catch (fetchError) {
      setError(fetchError as Error);
    } finally {
      setLoading(false);
    }
  };

  const refreshToken = () => {
    if (token && !checkTokenValidity(token)) {
      fetchToken();
    }
  };

  useEffect(() => {
    if (!token || !checkTokenValidity(token)) {
      fetchToken();
    } else {
      setLoading(false);
    }
  }, [token]);

  return { token, loading, error, refreshToken };
};

export default useTableauToken;
