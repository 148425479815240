import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../../../mixPanelEvents';
import {
  Grid,
  GridItem,
  Heading,
  Icons,
  Space,
  Toggler,
  Tooltip,
} from 'plume-ui';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { Routes } from '../../../config';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { Helmet } from 'react-helmet';

const SettingsContainer: FunctionComponent = () => {
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();
  const [developmentMode, setDevelopmentMode] = useState('disabled');
  const redirectToRoute = useRedirectToRoute();

  trackEvent(MixPanelEvents.SETTINGS);

  useEffect(() => {
    setDevelopmentMode(localStorage.getItem('developmentMode') || 'disabled');
  }, []);

  useEffect(() => {
    if (developmentMode !== localStorage.getItem('developmentMode')) {
      localStorage.setItem('developmentMode', developmentMode);
      trackEvent(MixPanelEvents.DEVELOPMENT_MODE, {
        DEVELOPMENTMODE: developmentMode,
      });
      redirectToRoute(Routes.Settings);
    }
  }, [developmentMode]);

  const toggleNames = [
    {
      key: 'enabled',
      label: t('enabled'),
    },
    {
      key: 'disabled',
      label: t('disabled'),
    },
  ];

  return (
    <div className="SettingsContainer">
      <Helmet title={`Panorama - ${t('settings.title')}`} />
      <Grid>
        <Space size="s" />
        <GridItem colSpan="12">
          <Heading level={1} ocrey>
            <FormattedMessage id="settings.title" />
            <Tooltip
              heading={t('settings.tooltip')}
              position="above"
              alignment="left"
              style={{ width: '250px' }}
            >
              <Icons.InfoIcon
                width={18}
                className="TableauLayout__title-icon"
              />
            </Tooltip>
          </Heading>
          <Space size="l" />
          <div>
            <FormattedMessage id="settings.developmentMode" />
            <Toggler
              toggleElements={toggleNames}
              value={developmentMode}
              onToggle={(selection) => {
                setDevelopmentMode(selection.key);
              }}
            />
          </div>
        </GridItem>
      </Grid>
    </div>
  );
};

export default SettingsContainer;
