import React, { FunctionComponent } from 'react';
import { Menu, TopBar } from 'plume-ui';
import useMenu from '../hooks/useMenu';
import { useRecoilValue } from 'recoil';
import { activeAccountAtom } from '../state';
import { useOktaAuth } from '@okta/okta-react';
import { DependencyContainer } from '../DependencyContainer';
import { useTrackEvent } from '../features/trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents } from '../mixPanelEvents';
import { APP_URL, Routes } from '../config';
import { MenuStyles } from 'plume-ui/dist/components/Menu/Menu';

const { loginService } = new DependencyContainer();

const AppLayout: FunctionComponent = ({ children }) => {
  const getMenuItems = useMenu();
  const activeAccount = useRecoilValue(activeAccountAtom);
  const { oktaAuth } = useOktaAuth();
  const trackEvent = useTrackEvent();

  const isDevMode = localStorage.getItem('developmentMode');

  const logout = async () => {
    loginService.removeAuthError();
    trackEvent(MixPanelEvents.LOGOUT);
    await oktaAuth.signOut({ postLogoutRedirectUri: APP_URL + Routes.Index });
  };

  return (
    <div className="AppLayout" data-testid="page">
      <TopBar
        classes={(current) => ({
          ...current,
          root: `${current.root} AppLayout__top-bar`,
        })}
        displayName={
          isDevMode === 'enabled'
            ? '**** DEVELOPMENT MODE PANORAMA ****'
            : undefined
        }
        userDropdown={{
          username: `${activeAccount?.firstName} ${activeAccount?.lastName}`,
          signOutAction: logout,
        }}
      />
      <div className="AppLayout__row">
        <Menu
          classes={(current: MenuStyles) => ({
            ...current,
            root: `${current.root} AppLayout__menu`,
            wrapper: `${current.wrapper} AppLayout__menu--wrapper`,
            appName: `${current.appName} AppLayout__menu--appName`,
          })}
          appName={'Panorama'}
          groupedItems={getMenuItems()}
        />
        <div className="AppLayout__content" style={{ width: '100%' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
