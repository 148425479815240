import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    deployment: {
      label: t('visualizations.deploymentLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    trend_of: {
      label: t('visualizations.trendOfLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'Location',
          value: 'Location',
        },
        {
          label: 'Device',
          value: 'Device',
        },
      ],
    },
    event_location: {
      label: t('visualizations.eventLocation'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      closeOnItemClick: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    trend_of: filterDropdownGroupItems(t).trend_of.items[0],
    event_location: filterDropdownGroupItems(t).event_location.items[0],
  };
}

export function aiSecurityEnablementConfig(t: TFunction) {
  return {
    title: t('visualizations.aiSecurityEnablement'),
    mixPanelName: 'AI Security Enablement',
    infoText: t('infoText.aiSecurityEnablement'),
    onlyShowRelevantCategoricalFilterValues: true,
    url: getTableauUrl(TableauName.aiSecurityEnablement),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'deployment',
      'tenant_type',
      'event_location',
    ],
    staticFilters: [],
    parameters: ['trend_of'],
  };
}
