/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { HttpClient } from '../../HttpClient';
import { Maybe } from '../../types';
import { getTableauTokenUrl } from '../../environments';

export default class TableauClient extends HttpClient {
  async fetchTableauToken(): Promise<AxiosResponse<Maybe<any>>> {
    const response = await this.get<AxiosResponse<Maybe<any>>>(
      getTableauTokenUrl(),
    );
    return response;
  }
}
