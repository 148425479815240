import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';

export const LoginTogglerItems: (
  t: (a: string) => string,
) => TogglerElementTypes[] = (t) => [
  {
    key: 'okta',
    label: t('okta'),
  },
  {
    key: 'partnerIDP',
    label: t('partnerIDP'),
  },
];
