import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    deployment: {
      label: t('visualizations.deploymentLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    time: {
      label: t('visualizations.timeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: '1 Week',
          value: 'Last 7 day',
        },
        {
          label: '1 Month',
          value: 'Last 30 day',
        },
        {
          label: '6 Months',
          value: 'Last 180 day',
        },
        {
          label: '1 Year',
          value: 'Last One year',
        },
      ],
    },
    policy: {
      label: t('visualizations.policyLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'online protection',
          value: 'online protection',
        },
        {
          label: 'ad blocking',
          value: 'ad blocking',
        },
        {
          label: 'kid appropriate',
          value: 'kid appropriate',
        },
        {
          label: 'teenager appropriate',
          value: 'teenager appropriate',
        },
        {
          label: 'no adult content',
          value: 'no adult content',
        },
      ],
    },
    provider: {
      label: t('visualizations.providerLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'brightcloud',
          value: 'brightcloud',
        },
        {
          label: 'webpulse',
          value: 'webpulse',
        },
        {
          label: 'akamai',
          value: 'akamai',
        },
      ],
    },
    event_type: {
      label: t('visualizations.eventTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    time: filterDropdownGroupItems(t).time.items[0],
    policy: filterDropdownGroupItems(t).policy.items[0],
    provider: filterDropdownGroupItems(t).provider.items[0],
  };
}

export function whitelistAnalyticsConfig(t: TFunction) {
  return {
    title: t('visualizations.whitelistAnalytics'),
    mixPanelName: 'Whitelist Analytics',
    infoText: t('infoText.whitelistAnalytics'),
    onlyShowRelevantCategoricalFilterValues: true,
    url: getTableauUrl(TableauName.whitelistAnalytics),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: ['tenant', 'deployment', 'tenant_type', 'event_type'],
    staticFilters: [],
    parameters: ['provider', 'time', 'policy'],
  };
}
