import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    date_range: {
      label: t('visualizations.dateRangeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: '30 days',
          value: '30 days',
        },
        {
          label: '90 days',
          value: '90 days',
        },
      ],
    },
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    home_type: {
      label: t('visualizations.homeTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    node_model: {
      label: t('visualizations.nodeModelLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [],
    },
    traffic_class: {
      label: t('visualizations.trafficClass'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [],
    },
    app: {
      label: t('visualizations.app'),
      defaultLabel: t('visualizations.defaultLabel'),
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      type: DropdownFilterType.SINGLE,
      items: [],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    date_range: filterDropdownGroupItems(t).date_range.items[0],
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    home_type: filterDropdownGroupItems(t).home_type.items[0],
    node_model: {
      label: 'All models',
      value: 'All models',
    },
    traffic_class: filterDropdownGroupItems(t).traffic_class.items[0],
    app: filterDropdownGroupItems(t).app.items[0],
  };
}

export function applicationQoEConfig(t: TFunction) {
  return {
    title: t('visualizations.applicationQoE'),
    mixPanelName: 'Application QoE',
    infoText: t('infoText.applicationQoE'),
    url: getTableauUrl(TableauName.applicationQoE),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'home_type',
      'node_model',
      'traffic_class',
      'app',
    ],
    staticFilters: [],
    parameters: ['date_range'],
  };
}
