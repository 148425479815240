import React from 'react';
import { Icons } from 'plume-ui';
import { MenuItems } from 'plume-ui/dist/components/Menu/Menu';
import { useRedirectToRoute } from '../utils/hooks/useRedirectToRoute';
import { Routes } from '../config';
import { useGetCurrentRoute } from '../utils/hooks/useGetCurrentRoute';
import { useTranslation } from 'react-i18next';
import {
  CustomerMenuFilledIcon,
  DevicesMenuFilledIcon,
  NodesMenuFilledIcon,
  PerformanceMenuFilledIcon,
  FullStackOptimizationMenuFilledIcon,
  FullStackOptimizationMenuIcon,
} from '../components/AdditionalMenuIcons';

const useMenu = () => {
  const getCurrentRoute = useGetCurrentRoute();
  const redirectToRoute = useRedirectToRoute();
  const { t } = useTranslation();
  const appFeatures: MenuItems = [
    {
      name: t('menu.categories.adapt'),
      iconLeft: <Icons.HomeMenuIcon width={24} />,
      iconLeftSelected: <Icons.HomeMenuFillIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected: [
        Routes.BandSteeringStatistics,
        Routes.ClientSteering,
        Routes.SteeringEnforcement,
        Routes.OptimizerOperations,
        Routes.OptimizationResponse,
        Routes.ChannelChangeOperation,
        Routes.ChannelDistribution,
        Routes.InterferenceAlgorithmEfficiency,
      ].includes(getCurrentRoute()),
      items: [
        {
          name: t('menu.deviceSteeringMenu.bandSteeringStatistics'),
          onItemClick: () => redirectToRoute(Routes.BandSteeringStatistics),
          isSelected: getCurrentRoute() === Routes.BandSteeringStatistics,
        },
        {
          name: t('menu.deviceSteeringMenu.clientSteeringStatistics'),
          onItemClick: () => redirectToRoute(Routes.ClientSteering),
          isSelected: getCurrentRoute() === Routes.ClientSteering,
        },
        {
          name: t('menu.deviceSteeringMenu.steeringEnforcement'),
          onItemClick: () => redirectToRoute(Routes.SteeringEnforcement),
          isSelected: getCurrentRoute() === Routes.SteeringEnforcement,
        },
        {
          name: t('menu.networkOperationsMenu.optimizerOperation'),
          onItemClick: () => redirectToRoute(Routes.OptimizerOperations),
          isSelected: getCurrentRoute() === Routes.OptimizerOperations,
        },
        {
          name: t('menu.networkOperationsMenu.optimizationResponse'),
          onItemClick: () => redirectToRoute(Routes.OptimizationResponse),
          isSelected: getCurrentRoute() === Routes.OptimizationResponse,
        },
        {
          name: t('menu.networkOperationsMenu.channelChangeOperation'),
          onItemClick: () => redirectToRoute(Routes.ChannelChangeOperation),
          isSelected: getCurrentRoute() === Routes.ChannelChangeOperation,
        },
        {
          name: t('menu.networkOperationsMenu.channelDistribution'),
          onItemClick: () => redirectToRoute(Routes.ChannelDistribution),
          isSelected: getCurrentRoute() === Routes.ChannelDistribution,
        },
        {
          name: t('menu.networkOperationsMenu.interferenceAlgorithmEfficiency'),
          onItemClick: () =>
            redirectToRoute(Routes.InterferenceAlgorithmEfficiency),
          isSelected:
            getCurrentRoute() === Routes.InterferenceAlgorithmEfficiency,
        },
        {
          name: t('menu.networkOperationsMenu.energyAwareActivity'),
          onItemClick: () => redirectToRoute(Routes.EnergyAwareActivity),
          isSelected: getCurrentRoute() === Routes.EnergyAwareActivity,
        },
      ],
    },
    {
      name: t('menu.categories.guardShield'),
      iconLeft: <Icons.ShieldIcon width={24} />,
      iconLeftSelected: <Icons.ShieldFillIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected: [
        Routes.NetworkThreats,
        Routes.SecurityThreatLeaderboard,
        Routes.DeviceThreatAnalysis,
        Routes.AISecurityEnablement,
        Routes.ProtectionValue,
        Routes.WhitelistAnalytics,
        Routes.ContentAccessProtection,
        Routes.OHPActiveandProtected,
      ].includes(getCurrentRoute()),
      items: [
        {
          name: t('menu.aiSecurityMenu.networkThreats'),
          onItemClick: () => redirectToRoute(Routes.NetworkThreats),
          isSelected: getCurrentRoute() === Routes.NetworkThreats,
        },
        {
          name: t('menu.aiSecurityMenu.securityThreatLeaderboard'),
          onItemClick: () => redirectToRoute(Routes.SecurityThreatLeaderboard),
          isSelected: getCurrentRoute() === Routes.SecurityThreatLeaderboard,
        },
        {
          name: t('menu.aiSecurityMenu.deviceThreatAnalysis'),
          onItemClick: () => redirectToRoute(Routes.DeviceThreatAnalysis),
          isSelected: getCurrentRoute() === Routes.DeviceThreatAnalysis,
        },
        {
          name: t('menu.aiSecurityMenu.aiSecurityEnablement'),
          onItemClick: () => redirectToRoute(Routes.AISecurityEnablement),
          isSelected: getCurrentRoute() === Routes.AISecurityEnablement,
        },
        {
          name: t('menu.aiSecurityMenu.protectionValue'),
          onItemClick: () => redirectToRoute(Routes.ProtectionValue),
          isSelected: getCurrentRoute() === Routes.ProtectionValue,
        },
        {
          name: t('menu.aiSecurityMenu.whitelistAnalytics'),
          onItemClick: () => redirectToRoute(Routes.WhitelistAnalytics),
          isSelected: getCurrentRoute() === Routes.WhitelistAnalytics,
        },
        {
          name: t('menu.aiSecurityMenu.contentAccessProtection'),
          onItemClick: () => redirectToRoute(Routes.ContentAccessProtection),
          isSelected: getCurrentRoute() === Routes.ContentAccessProtection,
        },
        {
          name: t('menu.aiSecurityMenu.OHPActiveandProtected'),
          onItemClick: () => redirectToRoute(Routes.OHPActiveandProtected),
          isSelected: getCurrentRoute() === Routes.OHPActiveandProtected,
        },
      ],
    },
    {
      name: t('menu.categories.fullStackOptimization'),
      iconLeft: <FullStackOptimizationMenuIcon width={24} />,
      iconLeftSelected: <FullStackOptimizationMenuFilledIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected:
        getCurrentRoute() === Routes.ApplicationPrioritization ||
        getCurrentRoute() === Routes.ApplicationQoE,
      items: [
        {
          name: t('menu.fullStackOptimizationMenu.applicationPrioritization'),
          onItemClick: () => redirectToRoute(Routes.ApplicationPrioritization),
          isSelected: getCurrentRoute() === Routes.ApplicationPrioritization,
        },
        {
          name: t('menu.fullStackOptimizationMenu.applicationQoE'),
          onItemClick: () => redirectToRoute(Routes.ApplicationQoE),
          isSelected: getCurrentRoute() === Routes.ApplicationQoE,
        },
      ],
    },
  ];

  const usageInsights: MenuItems = [
    {
      name: t('menu.categories.customers'),
      iconLeft: <Icons.CustomersIcon width={24} />,
      iconLeftSelected: <CustomerMenuFilledIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected:
        getCurrentRoute() === Routes.CustomerStatistics ||
        getCurrentRoute() === Routes.CustomerTrends ||
        getCurrentRoute() === Routes.CustomerSuccess,
      items: [
        {
          name: t('menu.customerMetricsMenu.customerStatistics'),
          onItemClick: () => redirectToRoute(Routes.CustomerStatistics),
          isSelected: getCurrentRoute() === Routes.CustomerStatistics,
        },
        {
          name: t('menu.customerMetricsMenu.customerTrends'),
          onItemClick: () => redirectToRoute(Routes.CustomerTrends),
          isSelected: getCurrentRoute() === Routes.CustomerTrends,
        },
        {
          name: t('menu.customerMetricsMenu.customerSuccess'),
          onItemClick: () => redirectToRoute(Routes.CustomerSuccess),
          isSelected: getCurrentRoute() === Routes.CustomerSuccess,
        },
      ],
    },
    {
      name: t('menu.categories.devices'),
      iconLeft: <Icons.BehaviorIcon width={24} />,
      iconLeftSelected: <DevicesMenuFilledIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected:
        getCurrentRoute() === Routes.DeviceTypingOverview ||
        getCurrentRoute() === Routes.DeviceBrandMarketShare ||
        getCurrentRoute() === Routes.DeviceModelAnalytics,
      items: [
        {
          name: t('menu.customerDeviceProfileMenu.deviceTypingOverview'),
          onItemClick: () => redirectToRoute(Routes.DeviceTypingOverview),
          isSelected: getCurrentRoute() === Routes.DeviceTypingOverview,
        },
        {
          name: t('menu.customerDeviceProfileMenu.deviceBrandMarketShare'),
          onItemClick: () => redirectToRoute(Routes.DeviceBrandMarketShare),
          isSelected: getCurrentRoute() === Routes.DeviceBrandMarketShare,
        },
        {
          name: t('menu.customerDeviceProfileMenu.deviceModelAnalytics'),
          onItemClick: () => redirectToRoute(Routes.DeviceModelAnalytics),
          isSelected: getCurrentRoute() === Routes.DeviceModelAnalytics,
        },
      ],
    },
    {
      name: t('menu.categories.performance'),
      iconLeft: <Icons.PerformanceIcon width={24} />,
      iconLeftSelected: <PerformanceMenuFilledIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected: getCurrentRoute() === Routes.NetworkSpeed,
      items: [
        {
          name: t('menu.networkPerformanceMenu.networkSpeed'),
          onItemClick: () => redirectToRoute(Routes.NetworkSpeed),
          isSelected: getCurrentRoute() === Routes.NetworkSpeed,
        },
      ],
    },
  ];

  const reports: MenuItems = [
    {
      name: t('menu.categories.nodesAccessPoints'),
      iconLeft: <Icons.DotCenterIcon width={24} />,
      iconLeftSelected: <NodesMenuFilledIcon width={24} />,
      iconRight: <Icons.ChevronDownIcon />,
      isSelected: getCurrentRoute() === Routes.NodeOnboarding,
      items: [
        {
          name: t('visualizations.nodeOnboarding'),
          onItemClick: () => redirectToRoute(Routes.NodeOnboarding),
          isSelected: getCurrentRoute() === Routes.NodeOnboarding,
        },
      ],
    },
  ];

  const grouped = [
    {
      fullName: t('menu.labels.appInsightsLong'),
      shortName: t('menu.labels.appInsightsShort'),
      items: appFeatures,
    },
    {
      fullName: t('menu.labels.usageInsightsLong'),
      shortName: t('menu.labels.usageInsightsShort'),
      items: usageInsights,
    },
    {
      fullName: t('menu.labels.reportsLong'),
      shortName: t('menu.labels.reportsShort'),
      items: reports,
    },
  ];
  return () => grouped;
};

export default useMenu;
