import {
  Divider,
  FilterDropdownGroup,
  Grid,
  GridItem,
  Heading,
  Icons,
  Space,
  Tooltip,
} from 'plume-ui';
import { FilterDropdownItem } from 'plume-ui/dist/components/FilterDropdown/FilterDropdown';
import { DropdownFilter } from 'plume-ui/dist/components/FilterDropdownGroup/FilterDropdownGroup';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useTrackEvent } from '../features/trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents, MixPanelEventTypes } from '../mixPanelEvents';
import Breadcrumbs from '../utils/components/Breadcrumbs';
import {
  DropdownFilterType,
  DropdownType,
} from '../features/visualizations/containers/tableau/config/tableauInterfaces';
import { getTableauApiUrl } from '../environments';
import useTableauToken from '../hooks/useTableauToken';

type TableauLayoutProps = {
  title: string;
  width?: string;
  height?: string;
  tableauUrl: string;
  additionalFilterValues: {
    [key: string]: FilterDropdownItem | FilterDropdownItem[];
  };
  filterDropdownGroupItems: { [key: string]: DropdownFilter };
  categoricalFilters?: string[];
  staticFilters?: string[];
  parameters?: string[];
  mixPanelName: string;
  infoText: string;
  firstItemAsDefault?: {
    [key: string]: boolean;
  };
  onlyShowRelevantCategoricalFilterValues?: boolean;
};

const retainedFiltersKey = 'retainedFilterValues';
const retainedParametersKey = 'retainedParameterValues';

const TableauLayout: FunctionComponent<TableauLayoutProps> = React.memo(
  ({
    title,
    width,
    height,
    tableauUrl,
    additionalFilterValues,
    filterDropdownGroupItems,
    categoricalFilters,
    staticFilters,
    parameters,
    mixPanelName,
    infoText,
    firstItemAsDefault,
    onlyShowRelevantCategoricalFilterValues = false,
  }) => {
    const trackEvent = useTrackEvent();

    const [vizReady, setVizReady] = useState(false);

    const { token, loading, refreshToken } = useTableauToken();

    const [filters, setFilter] = useState<{
      [key: string]: FilterDropdownItem | FilterDropdownItem[] | undefined;
    }>(additionalFilterValues);

    const { t } = useTranslation();
    const DEBUG = false;

    const vizRef = React.useRef<any>(null);

    const vizIsReady = async () => {
      setVizReady(true);
    };
    const onTableauUrlChanged = () => {
      setVizReady(false);
      refreshToken(); // Check token is still has more than 5 mins left or refresh it when navigating between pages
    };

    useEffect(() => {
      onTableauUrlChanged();
    }, [tableauUrl]);

    useEffect(() => {
      if (vizReady) {
        initializeFilters();
      }
    }, [vizReady]);

    const retainFilterValues = (selected) => {
      // Merge the selected values with the existing retained filter values
      const valuesToRetain = {
        ...getRetainedFilterValues(),
        ...selected,
      };

      // Save the updated values in local storage
      localStorage.setItem(retainedFiltersKey, JSON.stringify(valuesToRetain));
    };

    const retainParameterValues = (selected) => {
      // Merge the selected values with the existing retained parameter values
      const valuesToRetain = {
        ...getRetainedParameterValues(),
        ...selected,
      };

      // Save the updated values in local storage
      localStorage.setItem(
        retainedParametersKey,
        JSON.stringify(valuesToRetain),
      );
    };

    const saveParameterForReport = (selected) => {
      // Merge the selected values with the existing saved parameter values for the report
      const valuesToRetain = {
        ...getSavedParamsForReport(),
        ...selected,
      };

      // Save the updated values in local storage
      localStorage.setItem(mixPanelName, JSON.stringify(valuesToRetain));
    };

    const getRetainedFilterValues = () => {
      // Retrieve the retained filter values from local storage
      return JSON.parse(localStorage.getItem(retainedFiltersKey) || '{}');
    };

    const getRetainedParameterValues = () => {
      // Retrieve the retained parameter values from local storage
      return JSON.parse(localStorage.getItem(retainedParametersKey) || '{}');
    };

    const getSavedParamsForReport = () => {
      // Retrieve the saved parameter values for the report from local storage
      return JSON.parse(localStorage.getItem(mixPanelName) || '{}');
    };

    const getAppliedParameters = () => {
      if (!parameters?.length) return;

      // Map each parameter to an object with the parameter as key and the applied value as the value
      const appliedParameters = parameters
        ?.map((parameter) => {
          return { [parameter]: getAppliedParameterValue(parameter) };
        })
        // Merge all the parameter objects into a single object
        .reduce((obj, curr) => {
          return {
            ...obj,
            ...curr,
          };
        });

      return appliedParameters;
    };

    const getAppliedParameterValue = (parameter) => {
      // Get the retained parameter value for the given parameter
      const retained = getRetainedParameterValues()[parameter];

      // Check if the parameter is saved on the report
      const onReport = getSavedParamsForReport()[parameter];

      // Get the possible values for the parameter
      const possibleValues = filterDropdownGroupItems[parameter].items;

      // Get the default value from additional filter values
      const defaultValue = additionalFilterValues[parameter];

      // Check if the retained label exists in the possible values
      if (possibleValues.map((val) => val.label).includes(retained?.label)) {
        return possibleValues.find((item) => item.label === retained.label);
      } else if (
        possibleValues.map((val) => val.label).includes(onReport?.label)
      ) {
        // Check if the onReport label exists in the possible values
        return onReport;
      } else {
        // Return the default value if none of the above conditions are met
        return defaultValue;
      }
    };

    const getAppliedFilterValue = (filter) => {
      // Get the retained filter value for the given filter
      let retained = getRetainedFilterValues()[filter];

      // Get the possible values for the filter
      const possibleValues = filterDropdownGroupItems[filter].items;

      // Check if first item should be set as default
      const firstItemDefault = firstItemAsDefault?.[filter] || false;

      // Get the default value from additional filter values
      const defaultValue = additionalFilterValues[filter];

      // Handle MULTI filter type when retained is an empty array
      if (
        filterDropdownGroupItems[filter].type === DropdownFilterType.MULTI &&
        retained &&
        !retained.length
      ) {
        retained = [retained];
      }

      // Apply the filter logic based on retained values
      if (retained?.length) {
        // MULTI filter type
        const myArrayFiltered = possibleValues.filter((el) =>
          retained.some((val) => val?.value === el?.value),
        );
        return myArrayFiltered;
      }

      // Handle case where retained label does not exist in possible values
      if (!possibleValues.map((val) => val.label).includes(retained?.label)) {
        return firstItemDefault ? possibleValues[0] : defaultValue;
      }

      // Return the matching filter item or the default value
      return (
        possibleValues.find((item) => item.label === retained?.label) ||
        defaultValue
      );
    };

    /**
     * Initialize filters for the Tableau visualization.
     */
    async function initializeFilters(): Promise<void> {
      // Get the Tableau Viz instance from the ref.
      const vizInstance = vizRef.current as any | undefined;

      // If the Viz instance or workbook is not available, return early.
      if (!vizInstance || !vizInstance.workbook) {
        return;
      }

      // Get the workbook and active sheet.
      const workbook: any = vizInstance.workbook;
      if (!workbook) {
        return;
      }
      // TODO tableau has destroyed the types
      // previously this was of type Sheet but the property worksheets is missing from this type
      const sheet: any = workbook.activeSheet;

      // Determine the first worksheet (dashboard or regular worksheet).
      const firstWorksheet: any =
        sheet.sheetType === 'dashboard' ? sheet.worksheets[0] : sheet;

      try {
        // Get parameters from the workbook.
        const params: any[] = await workbook.getParametersAsync();

        // Log parameter information if in DEBUG mode.
        if (DEBUG) {
          params.forEach((element: any) => {
            console.log('param:', element.name);
            console.log(element.allowableValues);
          });
        }

        // Iterate through the specified parameters and update their values.
        parameters?.forEach((parameter: string) => {
          const paramName = params.find(
            (param: any) => param.name === parameter,
          )?.name;
          if (paramName) {
            updateDropdownValues(
              paramName,
              filterDropdownGroupItems[parameter].items,
              DropdownType.PARAMETER,
            );
          }
        });

        // Get filters from the first worksheet.
        const sheetFilters: any[] = await firstWorksheet.getFiltersAsync();

        // Iterate through the sheet filters.
        for (const appFilter of sheetFilters) {
          if (DEBUG) console.log('****** filter:', appFilter.fieldName);

          // Check if the filter exists in categoricalFilters or staticFilters.
          const existingFilter =
            categoricalFilters?.find(
              (filter) => filter === appFilter.fieldName,
            ) ||
            staticFilters?.find((filter) => filter === appFilter.fieldName);

          if (existingFilter) {
            // Get unique values for the filter.
            // Tableau gives an error using 'database' for some attributes so have a safety net
            const filterUniqueValues: any = await appFilter.getDomainAsync(
              onlyShowRelevantCategoricalFilterValues ? 'relevant' : 'database',
            );

            // Log filter information if in DEBUG mode.
            if (DEBUG) {
              console.log(
                'Filter = ',
                existingFilter,
                filterUniqueValues?.values,
              );
            }

            // Update the filter values.
            updateDropdownValues(
              existingFilter,
              filterUniqueValues?.values,
              DropdownType.FILTER,
            );
          }
        }
      } catch (error) {
        console.error('Error initializing filters:', error);
      }
    }

    const updateDropdownValues = (filterName, filterValues, dropdownType) => {
      const newFilterDropdownGroupItems: FilterDropdownItem[] = [];

      const selectAll = filterDropdownGroupItems[filterName].items.find(
        // Find the "Select All" item from the existing filterDropdownGroupItems
        (item) => item.label === 'All Items' && item.value === '',
      );

      if (selectAll) {
        // If a "Select All" item is found, add it to the new array
        newFilterDropdownGroupItems.push(selectAll);
      }

      if (categoricalFilters?.includes(filterName)) {
        // Check if the current filter is a categorical filter
        filterValues.forEach((element) => {
          let label = element.formattedValue;

          if (element.formattedValue === '' && filterName === 'node_model') {
            // Special handling for empty formattedValue in 'node_model' filter
            label = ' ';
          }

          if (element.formattedValue === '(All)') {
            // Special handling for '(All)' formattedValue
            label = 'All Items';
          }

          newFilterDropdownGroupItems.push({
            // Create a new dropdown item and add it to the new array
            label: label,
            value: element.formattedValue,
          });
        });

        filterDropdownGroupItems[
          filterName
        ].items = newFilterDropdownGroupItems; // Update the dropdown items for the current filter
      }

      if (DEBUG) {
        console.log(filterDropdownGroupItems[filterName]);
      }

      applyDefaultOrRetainedFilter(filterName, dropdownType); // Apply any default or retained filter/parameters for the current filter name and dropdown type
    };

    const applyDefaultOrRetainedFilter = (filterName, dropdownType) => {
      let defaultValue;

      if (dropdownType === DropdownType.PARAMETER) {
        // Get the applied parameter value for the given filter name
        defaultValue = getAppliedParameterValue(filterName);
      }

      if (dropdownType === DropdownType.FILTER) {
        // Get the applied filter value for the given filter name
        defaultValue = getAppliedFilterValue(filterName);
      }

      // Call the handleFilterSelect function to apply the default or retained value
      handleFilterSelect(filterName, defaultValue);
    };

    const handleFilterSelect = (
      filterStateKey: string,
      filter: FilterDropdownItem | FilterDropdownItem[] | undefined,
    ): void => {
      // Update the filters state with the new filter value
      setFilter((prevFilters) => ({
        ...prevFilters,
        [filterStateKey]: filter,
      }));

      // If no filter is selected, return early
      if (!filter) {
        return;
      }

      // Call the selectedFilter function with the selected filter and state key
      selectedFilter(filter as FilterDropdownItem, filterStateKey);

      // Create the Mixpanel filter value based on the selected filter
      const mixpanelFilter = !Array.isArray(filter)
        ? filter.value
        : filter.map((el) => el.value);

      // Track the event using Mixpanel
      trackEvent(MixPanelEvents.UPDATE_REPORT_FILTER, {
        TYPE: MixPanelEventTypes.TABLEAU,
        REPORT: mixPanelName,
        FILTER: filterStateKey,
        FILTER_VALUE: mixpanelFilter,
      });
    };

    const selectedFilter = (filter, key) => {
      // Check if vizRef's current property has workbook
      if (vizRef?.current?.workbook) {
        // Get the active sheet from vizRef's current workbook
        const activeSheet = vizRef.current.workbook.activeSheet;

        // If there is no active sheet, return early
        if (!activeSheet) {
          return;
        }

        // Combine staticFilters and categoricalFilters into an array called allFilters
        const allFilters = [
          ...(staticFilters || []),
          ...(categoricalFilters || []),
        ];

        // Apply filter
        if (allFilters.includes(key)) {
          const filterType = filterDropdownGroupItems[key]?.type;

          // Handle single select filter
          if (filterType === DropdownFilterType.SINGLE) {
            handleSingleSelectFilter(activeSheet, filter, key);
          }

          // Handle multi select filter
          if (filterType === DropdownFilterType.MULTI) {
            handleMultiSelectFilter(activeSheet, filter, key);
          }

          retainFilterValues({ [key]: filter });
        }

        // Apply parameter
        if (parameters?.includes(key)) {
          saveParameterForReport({ [key]: filter });
          retainParameterValues({ [key]: filter });

          if (DEBUG) console.log('about to apply Param', key, filter);

          // Change parameter value asynchronously
          activeSheet.workbook.changeParameterValueAsync(
            key,
            (filter as FilterDropdownItem).value,
          );
        }
      }
    };

    const handleSingleSelectFilter = (sheet, selected, key) => {
      if (selected.value === '') {
        // Apply filter with an empty array and set it to exclude mode
        sheet.applyFilterAsync(key, [], 'replace', {
          isExcludeMode: true,
        });
      } else {
        // Apply filter with the selected value
        if (DEBUG) console.log('Applying filter,', key, selected.value);
        sheet.applyFilterAsync(key, [selected.value], 'replace', {
          isExcludeMode: false,
        });
      }
    };

    const handleMultiSelectFilter = (sheet, selected, key) => {
      const selectedValues = selected.map((el) => el.value);
      if (DEBUG) console.log('Applying MultiSelectfilter,', key, selected);
      // Update filters object with the selected values
      setFilter({
        ...filters,
        [key]: selected,
      });

      // Determine the filter action based on the number of selected values
      const filterAction = selectedValues.length > 0 ? 'replace' : 'all';

      // Replace null values with 'Null' in the selected values array
      const sanitizedValues = selectedValues.map((val) =>
        val != null ? val : 'Null',
      );

      // Apply the filter with the sanitized values and filter action
      sheet.applyFilterAsync(key, sanitizedValues, filterAction);
    };

    useEffect(() => {
      setFilter({
        ...additionalFilterValues,
        ...getRetainedFilterValues(),
        ...getAppliedParameters(),
      });
    }, [additionalFilterValues]);

    // *** On load the token is fetched by the useTableauToken hook

    // *** When the TableauEmbed component receives a token or the token changes the loadViz()
    //     function is executed to load the Tableau dashboard

    useEffect(() => {
      if (token) {
        setTimeout(() => {
          const vizEl = vizRef.current;
          if (vizEl) {
            vizEl.addEventListener('firstinteractive', vizIsReady);
          }
        }, 1000);
      }
    }, [token]);

    return (
      <>
        <Helmet>
          <script type="module" src={getTableauApiUrl()} async></script>
        </Helmet>
        <div className="TableauLayout">
          <Helmet title={`Panorama - ${t(title)}`} />
          <Grid>
            <GridItem colSpan="12">
              <Breadcrumbs />
            </GridItem>
            <GridItem colSpan="12">
              <Space size="s" />
              <Space size="xxs" />
              <Heading level={1} ocrey>
                {t(`${title}`)}
                <Tooltip
                  heading={infoText}
                  position="right"
                  alignment="left"
                  style={{ width: '300px' }}
                >
                  <Icons.InfoIcon
                    width={18}
                    className="TableauLayout__title-icon"
                  />
                </Tooltip>
              </Heading>
              <Space size="l" />
              <Divider />
            </GridItem>

            <GridItem colSpan="12">
              <FilterDropdownGroup
                items={filterDropdownGroupItems}
                selectedItems={filters as any}
                onSelect={handleFilterSelect}
              />
              <Divider />
            </GridItem>
            <GridItem colSpan="12">
              {!loading ? (
                <tableau-viz
                  ref={vizRef}
                  id="tableauViz"
                  src={tableauUrl}
                  toolbar="hidden"
                  hide-tabs
                  token={token}
                />
              ) : (
                <div>Loading...</div>
              )}
            </GridItem>
            <Space size="l" />
          </Grid>
        </div>
      </>
    );
  },
);

export default TableauLayout;
