import { getEnvironmentId } from '../../../../../environments';

export interface FilterDropdownItem {
  label: string;
  value: string;
  getValue?: () => any;
}

export enum DropdownFilterType {
  SINGLE,
  MULTI,
}
export enum DropdownType {
  PARAMETER,
  FILTER,
}

export type DropdownFilter = {
  type: DropdownFilterType;
  label: string;
  defaultLabel: string;
  multiDefaultLabel?: string;
  defaultSelected?: number | number[];
  items: FilterDropdownItem[];
  noSearchMatchMessage?: string;
  searchPlaceholder?: string;
  closeOnItemClick?: boolean;
  searchBar?: boolean;
};

export interface TableauConfig {
  infoText: string;
  title: string;
  url: string;
  filterDropdownGroupItems: { [key: string]: DropdownFilter };
  additionalFilterValues: {
    [key: string]: FilterDropdownItem | FilterDropdownItem[];
  };
  categoricalFilters?: string[];
  staticFilters?: string[];
  parameters?: string[];
  mixPanelName: string;
  firstItemAsDefault?: {
    [key: string]: boolean;
  };
  onlyShowRelevantCategoricalFilterValues?: boolean;
}

export enum TableauName {
  aiSecurityEnablement = 'aiSecurityEnablement',
  contentAccessProtection = 'contentAccessProtection',
  deviceThreatAnalysis = 'deviceThreatAnalysis',
  networkThreats = 'networkThreats',
  protectionValue = 'protectionValue',
  securityThreatLeaderboard = 'securityThreatLeaderboard',
  whitelistAnalytics = 'whitelistAnalytics',
  deviceBrandMarketShare = 'deviceBrandMarketShare',
  deviceModelAnalytics = 'deviceModelAnalytics',
  deviceTypingOverview = 'deviceTypingOverview',
  customerStatistics = 'customerStatistics',
  customerTrends = 'customerTrends',
  customerSuccess = 'customerSuccess',
  bandSteering = 'bandSteering',
  clientSteering = 'clientSteering',
  steeringEnforcement = 'steeringEnforcement',
  channelChangeOperation = 'channelChangeOperation',
  channelDistribution = 'channelDistribution',
  interferenceAlgorithmEfficiency = 'interferenceAlgorithmEfficiency',
  optimizationResponse = 'optimizationResponse',
  optimizerOperations = 'optimizerOperations',
  networkSpeed = 'networkSpeed',
  nodeOnboarding = 'nodeOnboarding',
  OHPActiveandProtected = 'OHPActiveandProtected',
  applicationPrioritization = 'applicationPrioritization',
  applicationQoE = 'applicationQoE',
  energyAwareActivity = 'energyAwareActivity',
}

export enum TableauUrl {
  aiSecurityEnablement = 'https://10ay.online.tableau.com/t/plume/views/AISecurityEnablement/AISecurityEnablement',
  contentAccessProtection = 'https://10ay.online.tableau.com/t/plume/views/ContentAccessProtection/ContentAccessProtection',
  deviceThreatAnalysis = 'https://10ay.online.tableau.com/t/plume/views/DeviceThreatAnalysis/DeviceThreatAnalysis',
  networkThreats = 'https://10ay.online.tableau.com/t/plume/views/NetworkThreatsOverview/NetworkThreatsOverview',
  protectionValue = 'https://10ay.online.tableau.com/t/plume/views/ProtectionValue/ProtectionValue',
  securityThreatLeaderboard = 'https://10ay.online.tableau.com/t/plume/views/SecurityThreatLeaderboard/SecurityThreatLeaderboard',
  whitelistAnalytics = 'https://10ay.online.tableau.com/t/plume/views/WhitelistAnalytics/WhitelistAnalytics',
  deviceBrandMarketShare = 'https://10ay.online.tableau.com/t/plume/views/DeviceBrandMarketShare/DeviceBrandMarketShare',
  deviceModelAnalytics = 'https://10ay.online.tableau.com/t/plume/views/DevicesModelAnalytics/DevicesModelAnalytics',
  deviceTypingOverview = 'https://10ay.online.tableau.com/t/plume/views/DeviceTypingOverview/DeviceTypingOverview',
  customerStatistics = 'https://10ay.online.tableau.com/t/plume/views/CustomerStatistics/CustomerStatistics',
  customerTrends = 'https://10ay.online.tableau.com/t/plume/views/CustomerTrends/CustomerTrends',
  customerSuccess = 'https://10ay.online.tableau.com/t/plume/views/CustomerSuccess/CustomerSuccess',
  bandSteering = 'https://10ay.online.tableau.com/t/plume/views/Pre-AssociationBandSteeringEvents/Pre-AssociationBandSteering',
  clientSteering = 'https://10ay.online.tableau.com/t/plume/views/ClientSteering/ClientSteering',
  steeringEnforcement = 'https://10ay.online.tableau.com/t/plume/views/SteeringEnforcement/SteeringEnforcement',
  channelChangeOperation = 'https://10ay.online.tableau.com/t/plume/views/ChannelChangeandInterference/ChannelChangeandInterference',
  channelDistribution = 'https://10ay.online.tableau.com/t/plume/views/ChannelDistribution/ChannelDistribution',
  interferenceAlgorithmEfficiency = 'https://10ay.online.tableau.com/t/plume/views/InterferenceAlgorithmEfficiency/InterferenceAlgorithmEfficiency',
  optimizationResponse = 'https://10ay.online.tableau.com/t/plume/views/OptimizationStatusCode/OptimizationStatusCode',
  optimizerOperations = 'https://10ay.online.tableau.com/t/plume/views/OptimizerOperation/OptimizerOperation',
  networkSpeed = 'https://10ay.online.tableau.com/t/plume/views/NetworkSpeed/NetworkSpeed',
  nodeOnboarding = 'https://10ay.online.tableau.com/t/plume/views/ISPOnboarding/ISPOnboarding',
  OHPActiveandProtected = 'https://10ay.online.tableau.com/t/plume/views/OHPActiveandProtected/OHPActiveProtected',
  applicationPrioritization = 'https://10ay.online.tableau.com/t/plume/views/FSOApplicationPrioritization/ApplicationPrioritization',
  applicationQoE = 'https://10ay.online.tableau.com/t/plume/views/FSOApplicationQoE/ApplicationQoE',
  energyAwareActivity = 'https://10ay.online.tableau.com/t/plume/views/EnergyAwareActivity/Adapt-EnergyAwareActivity',
}

export enum TableauDevUrl {
  aiSecurityEnablement = 'https://10ay.online.tableau.com/t/plume/views/AISecurityEnablement_dev/AISecurityEnablement',
  contentAccessProtection = 'https://10ay.online.tableau.com/t/plume/views/ContentAccessProtection_dev/ContentAccessProtection',
  deviceThreatAnalysis = 'https://10ay.online.tableau.com/t/plume/views/DeviceThreatAnalysis_dev/DeviceThreatAnalysis',
  networkThreats = 'https://10ay.online.tableau.com/t/plume/views/NetworkThreatsOverview_dev/NetworkThreatsOverview',
  protectionValue = 'https://10ay.online.tableau.com/t/plume/views/ProtectionValue_dev/ProtectionValue',
  securityThreatLeaderboard = 'https://10ay.online.tableau.com/t/plume/views/SecurityThreatLeaderboard_dev/SecurityThreatLeaderboard',
  whitelistAnalytics = 'https://10ay.online.tableau.com/t/plume/views/WhitelistAnalytics_dev/WhitelistAnalytics',
  deviceBrandMarketShare = 'https://10ay.online.tableau.com/t/plume/views/DeviceBrandMarketShare_dev/DeviceBrandMarketShare',
  deviceModelAnalytics = 'https://10ay.online.tableau.com/t/plume/views/DevicesModelAnalytics_dev/DevicesModelAnalytics',
  deviceTypingOverview = 'https://10ay.online.tableau.com/t/plume/views/DeviceTypingOverview_dev/DeviceTypingOverview',
  customerStatistics = 'https://10ay.online.tableau.com/t/plume/views/CustomerStatistics_dev/CustomerStatistics',
  customerTrends = 'https://10ay.online.tableau.com/t/plume/views/CustomerTrends_dev/CustomerTrends',
  customerSuccess = 'https://10ay.online.tableau.com/t/plume/views/CustomerSuccess_dev/CustomerSuccess',
  bandSteering = 'https://10ay.online.tableau.com/t/plume/views/Pre-AssociationBandSteeringEvents_dev/Pre-AssociationBandSteering',
  clientSteering = 'https://10ay.online.tableau.com/t/plume/views/ClientSteering_dev/ClientSteering',
  steeringEnforcement = 'https://10ay.online.tableau.com/t/plume/views/SteeringEnforcement_dev/SteeringEnforcement',
  channelChangeOperation = 'https://10ay.online.tableau.com/t/plume/views/ChannelChangeandInterference_dev/ChannelChangeandInterference',
  channelDistribution = 'https://10ay.online.tableau.com/t/plume/views/ChannelDistribution_dev/ChannelDistribution',
  interferenceAlgorithmEfficiency = 'https://10ay.online.tableau.com/t/plume/views/InterferenceAlgorithmEfficiency_dev/InterferenceAlgorithmEfficiency',
  optimizationResponse = 'https://10ay.online.tableau.com/t/plume/views/OptimizationStatusCode_dev/OptimizationStatusCode',
  optimizerOperations = 'https://10ay.online.tableau.com/t/plume/views/OptimizerOperation_dev/OptimizerOperation',
  networkSpeed = 'https://10ay.online.tableau.com/t/plume/views/NetworkSpeed_dev/NetworkSpeed',
  nodeOnboarding = 'https://10ay.online.tableau.com/t/plume/views/ISPOnboarding_dev/ISPOnboarding',
  OHPActiveandProtected = 'https://10ay.online.tableau.com/t/plume/views/OHPActiveandProtected_dev/OHPActiveProtected',
  applicationPrioritization = 'https://10ay.online.tableau.com/t/plume/views/FSOApplicationPrioritization_dev/ApplicationPrioritization',
  applicationQoE = 'https://10ay.online.tableau.com/t/plume/views/FSOApplicationQoE_dev/ApplicationQoE',
  energyAwareActivity = 'https://10ay.online.tableau.com/t/plume/views/EnergyAwareActivity_dev/Adapt-EnergyAwareActivity',
}

export enum TableauRhoUrl {
  aiSecurityEnablement = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/AISecurityEnablement_jio/AISecurityEnablement',
  contentAccessProtection = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/ContentAccessProtection_jio/ContentAccessProtection',
  deviceThreatAnalysis = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/DeviceThreatAnalysis_jio/DeviceThreatAnalysis',
  networkThreats = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/NetworkThreatsOverview_jio/NetworkThreatsOverview',
  protectionValue = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/ProtectionValue_jio/ProtectionValue',
  securityThreatLeaderboard = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/SecurityThreatLeaderboard_jio/SecurityThreatLeaderboard',
  whitelistAnalytics = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/WhitelistAnalytics_jiro/WhitelistAnalytics',
  deviceBrandMarketShare = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/DeviceBrandMarketShare_jio/DeviceBrandMarketShare',
  deviceModelAnalytics = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/DevicesModelAnalytics_jio/DevicesModelAnalytics',
  deviceTypingOverview = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/DeviceTypingOverview_jio/DeviceTypingOverview',
  customerStatistics = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/CustomerStatistics_jio/CustomerStatistics',
  customerTrends = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/CustomerTrends_jio/CustomerTrends',
  customerSuccess = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/CustomerSuccess_jio/CustomerSuccess',
  bandSteering = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/Pre-AssociationBandSteeringEvents_jio/Pre-AssociationBandSteering',
  clientSteering = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/ClientSteering_jio/ClientSteering',
  steeringEnforcement = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/SteeringEnforcement_jio/SteeringEnforcement',
  channelChangeOperation = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/ChannelChangeandInterference_jio/ChannelChangeandInterference',
  channelDistribution = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/ChannelDistribution_jio/ChannelDistribution',
  interferenceAlgorithmEfficiency = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/InterferenceAlgorithmEfficiency_jio/InterferenceAlgorithmEfficiency',
  optimizationResponse = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/OptimizationStatusCode_jio/OptimizationStatusCode',
  optimizerOperations = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/OptimizerOperation_jio/OptimizerOperation',
  networkSpeed = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/NetworkSpeed_jio/NetworkSpeed',
  nodeOnboarding = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/ISPOnboarding_jio/ISPOnboarding',
  OHPActiveandProtected = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/OHPActiveandProtected_jio/OHPActiveProtected',
  applicationPrioritization = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/FSOApplicationPrioritization_jio/ApplicationPrioritization',
  applicationQoE = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/FSOApplicationQoE_jio/ApplicationQoE',
  energyAwareActivity = 'https://tableauserver.aps1.prod.rho.jiohs.net/views/Dummy-blank-dashboard/DummyDashboard',
}

export enum TableauDevRhoUrl {
  aiSecurityEnablement = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/AISecurityEnablement_jio/AISecurityEnablement',
  contentAccessProtection = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/ContentAccessProtection_jio/ContentAccessProtection',
  deviceThreatAnalysis = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/DeviceThreatAnalysis_jio/DeviceThreatAnalysis',
  networkThreats = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/NetworkThreatsOverview_jio/NetworkThreatsOverview',
  protectionValue = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/ProtectionValue_jio/ProtectionValue',
  securityThreatLeaderboard = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/SecurityThreatLeaderboard_jio/SecurityThreatLeaderboard',
  whitelistAnalytics = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/WhitelistAnalytics_jiro/WhitelistAnalytics',
  deviceBrandMarketShare = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/DeviceBrandMarketShare_jio/DeviceBrandMarketShare',
  deviceModelAnalytics = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/DevicesModelAnalytics_jio/DevicesModelAnalytics',
  deviceTypingOverview = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/DeviceTypingOverview_jio/DeviceTypingOverview',
  customerStatistics = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/CustomerStatistics_jio/CustomerStatistics',
  customerTrends = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/CustomerTrends_jio/CustomerTrends',
  customerSuccess = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/CustomerSuccess_jio/CustomerSuccess',
  bandSteering = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/Pre-AssociationBandSteeringEvents_jio/Pre-AssociationBandSteering',
  clientSteering = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/ClientSteering_jio/ClientSteering',
  steeringEnforcement = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/SteeringEnforcement_jio/SteeringEnforcement',
  channelChangeOperation = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/ChannelChangeandInterference_jio/ChannelChangeandInterference',
  channelDistribution = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/ChannelDistribution_jio/ChannelDistribution',
  interferenceAlgorithmEfficiency = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/InterferenceAlgorithmEfficiency_jio/InterferenceAlgorithmEfficiency',
  optimizationResponse = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/OptimizationStatusCode_jio/OptimizationStatusCode',
  optimizerOperations = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/OptimizerOperation_jio/OptimizerOperation',
  networkSpeed = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/NetworkSpeed_jio/NetworkSpeed',
  nodeOnboarding = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/ISPOnboarding_jio/ISPOnboarding',
  OHPActiveandProtected = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/OHPActiveandProtected_jio/OHPActiveProtected',
  applicationPrioritization = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/FSOApplicationPrioritization_jio/ApplicationPrioritization',
  applicationQoE = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/FSOApplicationQoE_jio/ApplicationQoE',
  energyAwareActivity = 'https://tableauserver.aps1.dev.rho.jiohs.net/views/Dummy-blank-dashboard/DummyDashboard',
}

export enum TableauLocalUrl {}

export enum TableauLocalDevUrl {}

export const getTableauUrl = (property: TableauName): string => {
  const environment = getEnvironmentId();
  const devMode =
    (localStorage.getItem('developmentMode') || 'disabled') === 'enabled';
  switch (environment) {
    case 'plume':
      return devMode
        ? TableauDevUrl[(property as unknown) as keyof TableauDevUrl] || null
        : TableauUrl[(property as unknown) as keyof TableauUrl] || null;
    case 'rho':
      return devMode
        ? TableauDevRhoUrl[(property as unknown) as keyof TableauDevRhoUrl] ||
            null
        : TableauRhoUrl[(property as unknown) as keyof TableauRhoUrl] || null;
    case 'local':
      return devMode
        ? TableauLocalDevUrl[
            (property as unknown) as keyof TableauLocalDevUrl
          ] || null
        : TableauLocalUrl[(property as unknown) as keyof TableauLocalUrl] ||
            null;
    default:
      return '';
  }
};
