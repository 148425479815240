/* eslint-disable @typescript-eslint/no-explicit-any */

import axios, { AxiosError, AxiosResponse } from 'axios';
import { APP_URL, LOGIN_SCREEN_ROUTE } from './config';
import getOktaProvider from './OktaProvider';

const axiosInstance = axios.create({
  timeout: 5000,
  headers: { Accept: 'application/json' },
  transitional: {
    silentJSONParsing: false,
    forcedJSONParsing: true,
    clarifyTimeoutError: true,
  },
});

axiosInstance.interceptors.response.use(
  async (response: AxiosResponse): Promise<AxiosResponse<any, any>> => {
    if (response.status === 401) {
      const oktaAuth = getOktaProvider();
      await oktaAuth.signOut({
        postLogoutRedirectUri: APP_URL + LOGIN_SCREEN_ROUTE,
      });
      return Promise.reject(response);
    }
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

export default axiosInstance;
