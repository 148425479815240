import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    deployment: {
      label: t('visualizations.deploymentLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    time: {
      label: t('visualizations.timeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: '1 Month',
          value: '30 days',
        },
        {
          label: '6 Months',
          value: '180 day',
        },
      ],
    },
    provider: {
      label: t('visualizations.providerLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    device_type: {
      label: t('visualizations.deviceLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [],
    },
    column_selector: {
      label: t('visualizations.columnSelectorLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'Event Count',
          value: 'Event Count',
        },
        {
          label: 'Location Count',
          value: 'Location Count',
        },
      ],
    },
    policy: {
      label: t('visualizations.policyLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'Security',
          value: 'Security',
        },
        {
          label: 'Ad Blocking',
          value: 'adBlocking',
        },
        {
          label: 'Content Access',
          value: 'Content Access',
        },
        {
          label: 'Other',
          value: 'Other',
        },
      ],
    },
    event_source: {
      label: t('visualizations.eventSourceLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    time: filterDropdownGroupItems(t).time.items[0],
    column_selector: filterDropdownGroupItems(t).column_selector.items[0],
    policy: filterDropdownGroupItems(t).policy.items[0],
  };
}

export function protectionValueConfig(t: TFunction) {
  return {
    title: t('visualizations.protectionValue'),
    mixPanelName: 'Protection Value',
    infoText: t('infoText.protectionValue'),
    onlyShowRelevantCategoricalFilterValues: true,
    url: getTableauUrl(TableauName.protectionValue),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'device_type',
      'event_source',
      'provider',
      'tenant',
      'deployment',
      'tenant_type',
    ],
    staticFilters: [],
    parameters: ['time', 'column_selector', 'policy'],
  };
}
