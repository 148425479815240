import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    date_range: {
      label: t('visualizations.dateRangeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: '7 days',
          value: '7 days',
        },
        {
          label: '30 days',
          value: '30 days',
        },
      ],
    },
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    extender_node_model: {
      label: t('visualizations.extenderNodeModelLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    date_range: filterDropdownGroupItems(t).date_range.items[0],
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    extender_node_model: {
      label: 'All models',
      value: 'All models',
    },
  };
}

export function networkSpeedConfig(t: TFunction) {
  return {
    title: t('visualizations.networkSpeed'),
    mixPanelName: 'Network Speed',
    infoText: t('infoText.networkSpeed'),
    url: getTableauUrl(TableauName.networkSpeed),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: ['tenant', 'tenant_type', 'extender_node_model'],
    staticFilters: [],
    parameters: ['date_range'],
  };
}
