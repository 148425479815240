export enum MixPanelEvents {
  AUTHENTICATION_SUCCESS = 'AUTHENTICATION_SUCCESS',
  LOGOUT = 'LOGOUT',
  DISPLAY_REPORT = 'DISPLAY_REPORT',
  UPDATE_REPORT_FILTER = 'UPDATE_REPORT_FILTER',
  SETTINGS = 'SETTINGS',
  DEVELOPMENT_MODE = 'DEVELOPMENT_MODE',
}

export enum MixPanelEventTypes {
  TABLEAU = 'Tableau',
}
