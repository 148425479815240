import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import TableauLayout from '../../../../layouts/TableauLayout';
import {
  AvailableTableauProperties,
  tableauConfiguration,
} from './config/config';
import { useTrackEvent } from '../../../trackingAnalytics/hooks/useTrackEvent';
import { MixPanelEvents, MixPanelEventTypes } from '../../../../mixPanelEvents';

type TableauContainerProps = {
  id: AvailableTableauProperties;
};

const TableauContainer: FunctionComponent<TableauContainerProps> = ({ id }) => {
  const trackEvent = useTrackEvent();
  const { t } = useTranslation();

  // Check tableau configuration contains the selected chart
  const chartExists: boolean = tableauConfiguration(t).hasOwnProperty(id)
    ? true
    : false;

  // Extract the config for the selected chart
  const chartConfig = tableauConfiguration(t)[id];

  trackEvent(MixPanelEvents.DISPLAY_REPORT, {
    TYPE: MixPanelEventTypes.TABLEAU,
    REPORT: chartConfig.mixPanelName,
  });

  if (!chartExists) {
    return null;
  }

  return (
    <TableauLayout
      title={chartConfig.title}
      tableauUrl={chartConfig.url}
      additionalFilterValues={chartConfig.additionalFilterValues}
      filterDropdownGroupItems={chartConfig.filterDropdownGroupItems}
      categoricalFilters={chartConfig.categoricalFilters}
      staticFilters={chartConfig.staticFilters}
      parameters={chartConfig.parameters}
      mixPanelName={chartConfig.mixPanelName}
      infoText={chartConfig.infoText}
      firstItemAsDefault={chartConfig.firstItemAsDefault}
      onlyShowRelevantCategoricalFilterValues={
        chartConfig.onlyShowRelevantCategoricalFilterValues
      }
    ></TableauLayout>
  );
};

export default TableauContainer;
