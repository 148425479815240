import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    date_range: {
      label: t('visualizations.dateRangeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'Lifetime to Date',
          value: 'Lifetime to Date',
        },
        {
          label: 'Current Quarter',
          value: 'Current Quarter',
        },
        {
          label: 'Previous Quarter',
          value: 'Previous Quarter',
        },
        {
          label: 'Current Year',
          value: 'Current Year',
        },
        {
          label: 'Previous Year',
          value: 'Previous Year',
        },
        {
          label: 'Current and Previous Quarters',
          value: 'Current and Previous Quarters',
        },
        {
          label: 'Current and Previous Years',
          value: 'Current and Previous Years',
        },
      ],
    },
    time_period: {
      label: t('visualizations.timePeriodLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [],
    },
    channel_partner: {
      label: t('visualizations.channelPartnerLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [],
    },
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    hard_launch: {
      label: t('visualizations.hardLaunchLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [],
    },
    display_metric: {
      label: t('visualizations.displayMetricLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      items: [
        {
          label: 'Provisioned',
          value: 'Provisioned',
        },
        {
          label: 'Active',
          value: 'Active',
        },
        {
          label: 'Archived',
          value: 'Archived',
        },
        {
          label: 'New Provisioned',
          value: 'New Provisioned',
        },
        {
          label: 'New Active',
          value: 'New Active',
        },
        {
          label: 'New Archived',
          value: 'New Archived',
        },
      ],
    },
    is_uprise: {
      label: t('visualizations.isUprise'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    is_flex: {
      label: t('visualizations.isFlex'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    date_range: filterDropdownGroupItems(t).date_range.items[0],
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    display_metric: filterDropdownGroupItems(t).display_metric.items[0],
  };
}

export function customerSuccessConfig(t: TFunction) {
  return {
    title: t('visualizations.customerSuccess'),
    mixPanelName: 'Customer Success',
    infoText: t('infoText.customerSuccess'),
    url: getTableauUrl(TableauName.customerSuccess),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'tenant_type',
      'time_period',
      'hard_launch',
      'channel_partner',
      'is_uprise',
      'is_flex',
    ],
    staticFilters: [],
    parameters: ['date_range', 'display_metric'],
  };
}
