import {
  OKTA_BASE_URL,
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  TABLEAU_PLUME_LIBRARY,
  TABLEAU_TOKEN_URL,
} from './config';

export type EnvironmentId = 'local' | 'rho' | 'plume';

export type Environment = {
  id: EnvironmentId;
  oktaBaseUrl: string;
  oktaIssuer: string;
  oktaClientId: string;
  tableauTokenUrl?: string;
  tableauLibrary?: string;
  idps?: {
    clientId: string;
  }[];
};

export const selectEnvironment = (hostname) => {
  if (hostname.startsWith('panorama')) {
    return {
      id: 'plume',
      oktaBaseUrl: 'https://external.sso.plume.com',
      oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
      oktaClientId: '0oafkqjypiTs7X4qa357',
      tableauTokenUrl: TABLEAU_TOKEN_URL,
      tableauLibrary: TABLEAU_PLUME_LIBRARY,
    };
  }
  if (hostname.startsWith('rho')) {
    return {
      id: 'rho',
      oktaBaseUrl: 'https://external.sso.plume.com',
      oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
      oktaClientId: '0oafkqjypiTs7X4qa357',
      tableauTokenUrl:
        'https://rho-prod-aps1-tableau-authenticator.aps1.prod.rho.jiohs.net/tableauToken',
      tableauLibrary:
        'https://tableauserver.aps1.prod.rho.jiohs.net/javascripts/api/tableau.embedding.3.latest.min.js',
      idps: [
        {
          clientId: '0oavrmoxh60cD0nYF357',
        },
      ],
    };
  }

  if (hostname === 'localhost') {
    return {
      id: 'local',
      oktaBaseUrl: 'https://external.sso.plume.com',
      oktaIssuer: 'https://external.sso.plume.com/oauth2/default',
      oktaClientId: '0oafkqjypiTs7X4qa357',
      tableauTokenUrl: TABLEAU_TOKEN_URL,
      tableauLibrary: TABLEAU_PLUME_LIBRARY,
    };
  }

  // Handle other cases or throw an error
  throw new Error('Unknown hostname');
};

const getEnvProp = (
  propName: keyof Environment,
  fallbackValue: string | undefined,
) => {
  const env = selectEnvironment(window.location.hostname);

  if (env !== undefined) {
    return env[propName] || fallbackValue;
  } else {
    return fallbackValue;
  }
};

export const getOktaClientId = () => {
  return getEnvProp('oktaClientId', OKTA_CLIENT_ID) as string;
};

export const getOktaBaseUrl = () => {
  return getEnvProp('oktaBaseUrl', OKTA_BASE_URL);
};

export const getOktaIssuer = () => {
  return getEnvProp('oktaIssuer', OKTA_ISSUER) as string;
};

export const getEnvironmentId = () => {
  return getEnvProp('id', 'local');
};

export const getTableauApiUrl = () => {
  return getEnvProp('tableauLibrary', TABLEAU_PLUME_LIBRARY) as string;
};

export const getTableauTokenUrl = () => {
  return getEnvProp('tableauTokenUrl', TABLEAU_TOKEN_URL) as string;
};

export const getIDPs = () => {
  const env = selectEnvironment(window.location.hostname);
  if (env?.idps) {
    return env.idps;
  }
  return [];
};
