import { atom } from 'recoil';
import { UserClaims } from '@okta/okta-auth-js';

export const counterAtom = atom<number>({
  key: 'counterAtom',
  default: 0,
});

export const activeAccountAtom = atom<UserClaims | undefined>({
  key: 'activeAccountAtom',
  default: undefined,
});

export const authErrorAtom = atom<string | undefined>({
  key: 'authErrorAtom',
  default: undefined,
});
