import LoginService from './features/login/LoginService';
import TableauClient from './utils/tableau/TableauClient';
import TableauService from './utils/tableau/TableauService';

export class DependencyContainer {
  loginService = new LoginService(this);
  tableauService = new TableauService(this);

  // Clients
  tableauClient = new TableauClient(this);
}
