import React, { FunctionComponent, useEffect } from 'react';
import { APP_URL, Routes } from '../../../config';
import { useOktaAuth } from '@okta/okta-react';
import { AuthError, LoginCallbackErrorProps } from '../types';
import { DependencyContainer } from '../../../DependencyContainer';
import FormattedMessage from '../../../utils/components/FormattedMessage';

const { loginService } = new DependencyContainer();

const LoginCallbackError: FunctionComponent<LoginCallbackErrorProps> = ({
  error,
}) => {
  const { oktaAuth } = useOktaAuth();

  const onError = async () => {
    loginService.setAuthError((error as unknown) as AuthError);
    await oktaAuth.signOut({ postLogoutRedirectUri: APP_URL + Routes.Login });
  };

  useEffect(() => {
    error && onError();
  }, [error]);

  if (loginService.getAuthError()) {
    return null;
  }

  return <FormattedMessage id="loading" />;
};

export default LoginCallbackError;
