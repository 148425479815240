import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from './tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
  };
}

function firstItemAsDefaultList(t: TFunction) {
  return {
    tenant: true,
  };
}

export function nodeOnboardingConfig(t: TFunction) {
  return {
    title: t('visualizations.nodeOnboarding'),
    mixPanelName: 'Node Onboarding',
    infoText: t('infoText.nodeOnboarding'),
    url: getTableauUrl(TableauName.nodeOnboarding),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: ['tenant', 'tenant_type'],
    staticFilters: [],
    parameters: [],
    firstItemAsDefault: firstItemAsDefaultList(t),
  };
}
