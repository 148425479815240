import { TableauConfig } from './tableauInterfaces';
import { aiSecurityEnablementConfig } from './aiSecurity/aiSecurityEnablement';
import { contentAccessProtectionConfig } from './aiSecurity/contentAccessProtection';
import { deviceThreatAnalysisConfig } from './aiSecurity/deviceThreatAnalysis';
import { networkThreatsConfig } from './aiSecurity/networkThreats';
import { OHPActiveandProtectedConfig } from './aiSecurity/OHPActiveandProtected';
import { protectionValueConfig } from './aiSecurity/protectionValue';
import { securityThreatLeaderboardConfig } from './aiSecurity/securityThreatLeaderboard';
import { whitelistAnalyticsConfig } from './aiSecurity/whitelistAnalytics';
import { deviceBrandMarketShareConfig } from './customerDeviceProfile/deviceBrandMarketShare';
import { deviceModelAnalyticsConfig } from './customerDeviceProfile/deviceModelAnalytics';
import { deviceTypingOverviewConfig } from './customerDeviceProfile/deviceTypingOverview';
import { customerStatisticsConfig } from './customerMetrics/customerStatistics';
import { customerSuccessConfig } from './customerMetrics/customerSuccess';
import { customerTrendsConfig } from './customerMetrics/customerTrends';
import { bandSteeringConfig } from './deviceSteering/bandSteering';
import { clientSteeringConfig } from './deviceSteering/clientSteering';
import { steeringEnforcementConfig } from './deviceSteering/steeringEnforcement';
import { channelChangeOperationConfig } from './networkOperations/channelChangeOperation';
import { channelDistributionConfig } from './networkOperations/channelDistribution';
import { interferenceAlgorithmEfficiencyConfig } from './networkOperations/interferenceAlgorithmEfficiency';
import { optimizationResponseConfig } from './networkOperations/optimizationResponse';
import { optimizerOperationsConfig } from './networkOperations/optimizerOperations';
import { networkSpeedConfig } from './networkPerformance/networkSpeed';
import { nodeOnboardingConfig } from './nodeOnboarding';
import { applicationPrioritizationConfig } from './fullStackOptimization/applicationPrioritization';
import { applicationQoEConfig } from './fullStackOptimization/applicationQoE';
import { energyAwareActivityConfig } from './networkOperations/energyAwareActivity';

export type AvailableTableauProperties =
  | 'aiSecurityEnablement'
  | 'contentAccessProtection'
  | 'deviceThreatAnalysis'
  | 'networkThreats'
  | 'protectionValue'
  | 'securityThreatLeaderboard'
  | 'whitelistAnalytics'
  | 'deviceBrandMarketShare'
  | 'deviceModelAnalytics'
  | 'deviceTypingOverview'
  | 'customerStatistics'
  | 'customerTrends'
  | 'customerSuccess'
  | 'bandSteering'
  | 'clientSteering'
  | 'steeringEnforcement'
  | 'channelChangeOperation'
  | 'channelDistribution'
  | 'interferenceAlgorithmEfficiency'
  | 'optimizationResponse'
  | 'optimizerOperations'
  | 'networkSpeed'
  | 'nodeOnboarding'
  | 'OHPActiveandProtected'
  | 'applicationPrioritization'
  | 'applicationQoE'
  | 'energyAwareActivity';

export type AvailableTableauConfigurations = Record<
  AvailableTableauProperties,
  TableauConfig
>;

export function tableauConfiguration(t: any): AvailableTableauConfigurations {
  return {
    aiSecurityEnablement: aiSecurityEnablementConfig(t),
    contentAccessProtection: contentAccessProtectionConfig(t),
    deviceThreatAnalysis: deviceThreatAnalysisConfig(t),
    networkThreats: networkThreatsConfig(t),
    protectionValue: protectionValueConfig(t),
    securityThreatLeaderboard: securityThreatLeaderboardConfig(t),
    whitelistAnalytics: whitelistAnalyticsConfig(t),
    deviceBrandMarketShare: deviceBrandMarketShareConfig(t),
    deviceModelAnalytics: deviceModelAnalyticsConfig(t),
    deviceTypingOverview: deviceTypingOverviewConfig(t),
    customerStatistics: customerStatisticsConfig(t),
    customerTrends: customerTrendsConfig(t),
    customerSuccess: customerSuccessConfig(t),
    bandSteering: bandSteeringConfig(t),
    clientSteering: clientSteeringConfig(t),
    steeringEnforcement: steeringEnforcementConfig(t),
    channelChangeOperation: channelChangeOperationConfig(t),
    channelDistribution: channelDistributionConfig(t),
    interferenceAlgorithmEfficiency: interferenceAlgorithmEfficiencyConfig(t),
    optimizationResponse: optimizationResponseConfig(t),
    optimizerOperations: optimizerOperationsConfig(t),
    networkSpeed: networkSpeedConfig(t),
    nodeOnboarding: nodeOnboardingConfig(t),
    OHPActiveandProtected: OHPActiveandProtectedConfig(t),
    applicationPrioritization: applicationPrioritizationConfig(t),
    applicationQoE: applicationQoEConfig(t),
    energyAwareActivity: energyAwareActivityConfig(t),
  };
}
