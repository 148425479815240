import { useLocation, matchPath } from 'react-router-dom';
import { Routes } from '../../config';

export const useGetCurrentRoute = () => {
  const location = useLocation();

  return () => {
    const route = getMatchedRoute(location.pathname);
    return route.path as Routes;
  };
};

export const useGetCurrentRouteUrl = () => {
  const location = useLocation();

  return () => {
    const route = getMatchedRoute(location.pathname);
    return route.url as string;
  };
};

const getMatchedRoute = (pathname: string) => {
  const route = matchPath(pathname, Object.values(Routes).reverse());

  if (route == null) {
    throw new Error(
      `Cannot match current route '${pathname}' to defined routes`,
    );
  }

  return route;
};
