import React, { FunctionComponent } from 'react';
import { BreadcrumbNavigation, Breadcrumb } from 'plume-ui';
import useMenu from '../../hooks/useMenu';

const Breadcrumbs: FunctionComponent = () => {
  const getMenu = useMenu();
  const menu = getMenu()
    .map((menuGroup) => menuGroup.items)
    .reduce((curr, prev) => curr.concat(prev));
  const parent = menu.find((item) => item.isSelected);
  const child = parent?.items?.find((item) => item.isSelected);

  return (
    <BreadcrumbNavigation>
      {parent !== undefined && <Breadcrumb label={parent?.name || ''} />}
      {child !== undefined && <Breadcrumb label={child?.name || ''} />}
    </BreadcrumbNavigation>
  );
};

export default Breadcrumbs;
