import { OktaAuth } from '@okta/okta-auth-js';
import { LoginUrls } from './config';
import { getOktaClientId, getOktaIssuer } from './environments';

const redirectUri = LoginUrls.getRedirectUriForOkta();
let instance: OktaAuth;

const getOktaProvider = () => {
  return (
    instance ??
    (instance = new OktaAuth({
      issuer: getOktaIssuer(),
      clientId: getOktaClientId(),
      redirectUri,
      postLogoutRedirectUri: redirectUri,
      scopes: ['openid', 'profile', 'email', 'plume'],
      responseType: 'id_token',
      tokenManager: {
        autoRenew: true,
      },
    }))
  );
};

export default getOktaProvider;
