import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    date_range: {
      label: t('visualizations.dateRangeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: '7 days',
          value: '7 days',
        },
        {
          label: '30 days',
          value: '30 days',
        },
      ],
    },
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    home_type: {
      label: t('visualizations.homeTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    node_model: {
      label: t('visualizations.nodeModelLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [],
    },
    is_uprise: {
      label: t('visualizations.isUprise'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    is_flex: {
      label: t('visualizations.isFlex'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    date_range: filterDropdownGroupItems(t).date_range.items[0],
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    home_type: filterDropdownGroupItems(t).home_type.items[0],
    node_model: {
      label: 'All models',
      value: 'All models',
    },
  };
}

export function steeringEnforcementConfig(t: TFunction) {
  return {
    title: t('visualizations.steeringEnforcement'),
    mixPanelName: 'Steering Enforcement',
    infoText: t('infoText.steeringEnforcement'),
    url: getTableauUrl(TableauName.steeringEnforcement),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'node_model',
      'kick_type',
      'steering_type',
      'home_type',
      'tenant_type',
      'is_uprise',
      'is_flex',
    ],
    staticFilters: [],
    parameters: ['date_range'],
  };
}
