import React, { FunctionComponent } from 'react';
import './root.scss';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { Routes } from './config';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginUrls } from './urls';
import LoginContainer from './features/login/containers/LoginContainer';
import ForAuthenticated from './ForAuthenticated';
import { useRedirectToRoute } from './utils/hooks/useRedirectToRoute';
import { DependencyContainer } from './DependencyContainer';
import LoginCallbackError from './features/login/components/LoginCallbackError';
import AppLayout from './layouts/AppLayout';
import TableauContainer from './features/visualizations/containers/tableau/Tableau';
import SettingsContainer from './features/settings/containers/SettingsContainer';
import { getOktaClientId, getOktaIssuer } from './environments';

const redirectUri = LoginUrls.getRedirectUriForOkta();
const oktaAuth = new OktaAuth({
  issuer: getOktaIssuer(),
  clientId: getOktaClientId(),
  redirectUri,
  postLogoutRedirectUri: redirectUri,
  scopes: ['openid', 'email', 'plume'],
  responseType: 'id_token',
  tokenManager: {
    autoRenew: true,
  },
});
const { loginService } = new DependencyContainer();
const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) => {
  const targetRoute = loginService.getTargetRoute();
  if (targetRoute) {
    window.location.href = targetRoute;
    loginService.removeTargetRoute();
    return;
  }
  const url = originalUri
    ? toRelativeUrl(originalUri, window.location.origin)
    : Routes.Index;
  window.location.href = url;
};

const AppContainer: FunctionComponent = () => {
  const { pathname } = useLocation();
  const redirectToRoute = useRedirectToRoute();
  const onAuthRequired = () => {
    if (pathname === Routes.Login) {
      return;
    }
    loginService.setTargetRoute(pathname);
    // Perform Okta Authentication first
    redirectToRoute(Routes.Login);
  };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={onAuthRequired}
      restoreOriginalUri={restoreOriginalUri}
    >
      <div className="AppContainer">
        <Switch>
          <Route path={Routes.Login} component={LoginContainer} />
          <Route path={Routes.LoginCallback}>
            <LoginCallback errorComponent={LoginCallbackError} />
          </Route>
          <SecureRoute path={Routes.AISecurityEnablement}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="aiSecurityEnablement" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ContentAccessProtection}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="contentAccessProtection" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.DeviceThreatAnalysis}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="deviceThreatAnalysis" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.NetworkThreats}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="networkThreats" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ProtectionValue}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="protectionValue" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.SecurityThreatLeaderboard}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="securityThreatLeaderboard" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.OHPActiveandProtected}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="OHPActiveandProtected" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ApplicationPrioritization}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="applicationPrioritization" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ApplicationQoE}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="applicationQoE" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.WhitelistAnalytics}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="whitelistAnalytics" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.DeviceBrandMarketShare}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="deviceBrandMarketShare" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.DeviceTypingOverview}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="deviceTypingOverview" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.CustomerStatistics}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="customerStatistics" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.CustomerTrends}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="customerTrends" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.CustomerSuccess}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="customerSuccess" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.BandSteeringStatistics}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="bandSteering" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ClientSteering}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="clientSteering" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.SteeringEnforcement}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="steeringEnforcement" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ChannelChangeOperation}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="channelChangeOperation" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.ChannelDistribution}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="channelDistribution" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.InterferenceAlgorithmEfficiency}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="interferenceAlgorithmEfficiency" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.OptimizationResponse}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="optimizationResponse" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.OptimizerOperations}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="optimizerOperations" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.NetworkSpeed}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="networkSpeed" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.NodeOnboarding}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="nodeOnboarding" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.DeviceModelAnalytics}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="deviceModelAnalytics" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.EnergyAwareActivity}>
            <ForAuthenticated>
              <AppLayout>
                <TableauContainer id="energyAwareActivity" />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <SecureRoute path={Routes.Settings}>
            <ForAuthenticated>
              <AppLayout>
                <SettingsContainer />
              </AppLayout>
            </ForAuthenticated>
          </SecureRoute>
          <Route path={Routes.Index}>
            <Redirect to={Routes.Login} />
          </Route>
        </Switch>
      </div>
    </Security>
  );
};

export default AppContainer;
