export enum Routes {
  Index = '/',
  Login = '/login',
  Tableau = '/tableau/:id',
  CustomerStatistics = '/report/customer-statistics',
  CustomerTrends = '/report/customer-trends',
  CustomerSuccess = '/report/customer-success',
  BandSteeringStatistics = '/report/band-steering-statistics',
  ClientSteering = '/report/client-steering',
  SteeringEnforcement = '/report/steering-enforcement',
  OptimizerOperations = '/report/optimizer-operations',
  OptimizationResponse = '/report/optimization-response',
  ChannelChangeOperation = '/report/channel-change-operation',
  ChannelDistribution = '/report/channel-distribution',
  InterferenceAlgorithmEfficiency = '/report/interference-algorithm-efficiency',
  NetworkSpeed = '/report/network-speed',
  NetworkThreats = '/report/network-threats',
  SecurityThreatLeaderboard = '/report/security-threat-leaderboard',
  DeviceThreatAnalysis = '/report/device-threat-analysis',
  AISecurityEnablement = '/report/ai-security-enablement',
  ContentAccessProtection = '/report/content-access-protection',
  ProtectionValue = '/report/protection-value',
  WhitelistAnalytics = '/report/whitelist-analytics',
  DeviceTypingOverview = '/report/device-typing-overview',
  DeviceBrandMarketShare = '/report/device-brand-market-share',
  DeviceModelAnalytics = '/report/device-model-analytics',
  NodeOnboarding = '/report/node-onboarding',
  LoginCallback = '/implicit/callback',
  Settings = '/settings',
  OHPActiveandProtected = '/report/ohp-active-and-protected',
  ApplicationPrioritization = '/report/application-prioritization',
  ApplicationQoE = '/report/application-qoe',
  EnergyAwareActivity = '/report/energy-aware-activity',
}

export const LOGIN_SCREEN_ROUTE = Routes.Login;

export const APP_URL = process.env.REACT_APP_URL || window.location.origin;
export const OKTA_BASE_URL =
  process.env.REACT_APP_OKTA_BASE || 'https://external.sso.plume.com';
export const OKTA_ISSUER =
  process.env.REACT_APP_OKTA_ISSUER || `${OKTA_BASE_URL}/oauth2/default`;
export const OKTA_CLIENT_ID =
  process.env.REACT_APP_OKTA_CLIENT_ID || '0oafkqjypiTs7X4qa357';
export const OKTA_REDIRECT_URI =
  process.env.REACT_APP_OKTA_REDIRECT_URI ||
  `${window.location.origin}/implicit/callback`;
export const OKTA_LOGOUT_URI =
  process.env.REACT_APP_OKTA_LOGOUT_URI || window.location.origin;

export const TRANSLATION_FILE_PATH_TEMPLATE =
  process.env.REACT_APP_TRANSLATION_FILE_PATH_TEMPLATE ||
  '/{{lng}}.json' +
    (process.env.REACT_APP_VERSION
      ? '?version=' + process.env.REACT_APP_VERSION
      : '');

export const MIXPANEL_ID =
  process.env.REACT_APP_MIXPANEL_ID || '9203e9070bd8ce6ccfab4e0c36b091e6';

export const TABLEAU_TOKEN_URL =
  'https://plume-common-prod-usw2-tableau-authenticator.data.plumenet.io/tableauToken';

export const TABLEAU_PLUME_LIBRARY =
  'https://10ay.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';

type Urls = Record<string, CallableFunction>;

export const LoginUrls: Urls = {
  getRedirectUriForOkta: () => APP_URL + Routes.LoginCallback,
};
