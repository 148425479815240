import React, { FunctionComponent, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Button, TopBar, Toggler } from 'plume-ui';
import useLoginWithOkta from '../hooks/useLoginWithOkta';
import FormattedMessage from '../../../utils/components/FormattedMessage';
import { DependencyContainer } from '../../../DependencyContainer';
import LoginContainerAuthError from '../components/LoginContainerAuthError';
import { BoxStyles } from 'plume-ui/dist/components/Box/Box';
import { useTranslation } from 'react-i18next';
import { useRedirectToRoute } from '../../../utils/hooks/useRedirectToRoute';
import { LoginTogglerItems } from '../../../helpers';
import { TogglerElementTypes } from 'plume-ui/dist/components/Toggler/Toggler';
import { LoginOptionKey } from '../types';
import { getIDPs } from '../../../environments';

const { loginService } = new DependencyContainer();

const LoginContainer: FunctionComponent = () => {
  const { login, isAuthenticated } = useLoginWithOkta();
  const redirectToRoute = useRedirectToRoute();
  const authError = loginService.getAuthError();
  const { t } = useTranslation();

  if (isAuthenticated) {
    const targetRoute = loginService.getTargetRoute();
    redirectToRoute(targetRoute);
  }
  const [loginOption, setLoginOption] = useState<LoginOptionKey>('okta');
  const handleLoginToggle = (item: TogglerElementTypes) => {
    setLoginOption(item.key as LoginOptionKey);
  };

  const idps: { clientId: string }[] = getIDPs();
  const idp = idps[0]?.clientId;

  return (
    <>
      <Helmet>
        <title>{t('pageTitles.login')}</title>
      </Helmet>
      <TopBar />
      <div className="LoginBackground" />

      <div className="LoginContainer p-xl">
        <Box
          classes={(current: BoxStyles) => ({
            ...current,
            root: `${current.root} LoginContainer__box`,
          })}
        >
          <h3 className="LoginContainer__app-name">
            <FormattedMessage id="appName" />
          </h3>
          <h1 className="LoginContainer__heading m-b-l m-t-s">
            <FormattedMessage id="signIn" />
          </h1>
          {!!authError && <LoginContainerAuthError {...authError} />}
          <div className="LoginContainer__login-button">
            {idp && (
              <Toggler
                variant="large"
                toggleElements={LoginTogglerItems(t)}
                onToggle={handleLoginToggle}
                value={loginOption}
              ></Toggler>
            )}
            <Button
              styleVariant="superprimary"
              onClick={() => login(loginOption, idp)}
            >
              <FormattedMessage id="login" />
            </Button>
          </div>
        </Box>
      </div>
    </>
  );
};

export default LoginContainer;
