import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },

    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    deployment: {
      label: t('visualizations.deploymentLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    date_range: {
      label: t('visualizations.dateRangeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          value: 'All',
          label: 'All',
        },
        {
          label: '7 days',
          value: '7 days',
        },
        {
          label: '30 days',
          value: '30 days',
        },
      ],
    },
    device_type: {
      label: t('visualizations.deviceLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          value: 'All',
          label: 'All',
        },
        {
          value: 'Noninteractive-iot',
          label: 'Noninteractive-iot',
        },
        {
          value: 'Interactive-iot',
          label: 'Interactive-iot',
        },
        {
          value: 'All-iot',
          label: 'All-iot',
        },
        {
          value: 'Non-iot',
          label: 'Non-iot',
        },
      ],
    },
    event_source: {
      label: t('visualizations.eventSourceLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    category_name: {
      label: t('visualizations.threatTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    provider_orig: {
      label: t('visualizations.providerOrigLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    statistic_selector: {
      label: t('visualizations.statisticsSelectorLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'Count',
          value: 'Count',
        },
        {
          label: 'Median',
          value: 'Median',
        },
        {
          label: 'Average',
          value: 'Average',
        },
        {
          label: '90 Percentile',
          value: '90 Percentile',
        },
      ],
    },
    event_location: {
      label: t('visualizations.eventLocation'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      closeOnItemClick: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    date_range: filterDropdownGroupItems(t).date_range.items[2],
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    device_type: filterDropdownGroupItems(t).device_type.items[0],
    statistic_selector: filterDropdownGroupItems(t).statistic_selector.items[0],
    event_location: filterDropdownGroupItems(t).event_location.items[0],
  };
}

export function networkThreatsConfig(t: TFunction) {
  return {
    title: t('visualizations.networkThreats'),
    mixPanelName: 'Network Threats',
    infoText: t('infoText.networkThreats'),
    onlyShowRelevantCategoricalFilterValues: true,
    url: getTableauUrl(TableauName.networkThreats),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'tenant_type',
      'deployment',
      'event_source',
      'category_name',
      'provider_orig',
      'event_location',
    ],
    staticFilters: [],
    parameters: ['statistic_selector', 'date_range', 'device_type'],
  };
}
