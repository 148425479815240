import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    deployment: {
      label: t('visualizations.deploymentLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    device_type: {
      label: t('visualizations.deviceLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          value: 'All',
          label: 'All',
        },
        {
          value: 'Noninteractive-iot',
          label: 'Noninteractive-iot',
        },
        {
          value: 'Interactive-iot',
          label: 'Interactive-iot',
        },
        {
          value: 'All-iot',
          label: 'All-iot',
        },
        {
          value: 'Non-iot',
          label: 'Non-iot',
        },
      ],
    },
    device_category: {
      label: t('visualizations.categoryNameLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      searchBar: true,
      items: [],
    },
    count_unit: {
      label: t('visualizations.countUnitLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'Device',
          value: 'Device',
        },
        {
          label: 'Location',
          value: 'Location',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    device_type: filterDropdownGroupItems(t).device_type.items[0],
    count_unit: filterDropdownGroupItems(t).count_unit.items[0],
  };
}

export function deviceBrandMarketShareConfig(t: TFunction) {
  return {
    title: t('visualizations.deviceBrandMarketShare'),
    mixPanelName: 'Device Brand Market Share',
    infoText: t('infoText.deviceBrandMarketShare'),
    onlyShowRelevantCategoricalFilterValues: true,
    url: getTableauUrl(TableauName.deviceBrandMarketShare),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'tenant_type',
      'device_category',
      'deployment',
    ],
    staticFilters: [],
    parameters: ['device_type', 'count_unit'],
  };
}
