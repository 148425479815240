import { TFunction } from 'i18next';
import {
  DropdownFilterType,
  TableauName,
  getTableauUrl,
} from '../tableauInterfaces';

function filterDropdownGroupItems(t: TFunction) {
  return {
    tenant: {
      label: t('visualizations.tenantLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      noSearchMatchMessage: t('visualizations.search.noMatch'),
      searchPlaceholder: t('visualizations.search.placeholder'),
      closeOnItemClick: true,
      searchBar: true,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    tenant_type: {
      label: t('visualizations.tenantTypeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All Items',
          value: '',
        },
      ],
    },
    deployment: {
      label: t('visualizations.deploymentLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    time: {
      label: t('visualizations.timeLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: '1 Day',
          value: '24 hr',
        },
        {
          label: '1 Week',
          value: '1 week',
        },
        {
          label: '1 Month',
          value: '1 month',
        },
        {
          label: '6 Months',
          value: '180 days',
        },
      ],
    },
    provider: {
      label: t('visualizations.providerLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'brightcloud',
          value: 'brightcloud',
        },
        {
          label: 'webpulse',
          value: 'webpulse',
        },
        {
          label: 'akamai',
          value: 'akamai',
        },
      ],
    },
    profile: {
      label: t('visualizations.profileLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'All',
          value: 'All',
        },
        {
          label: 'adBlocking',
          value: 'Adblocking',
        },
        {
          label: 'kids',
          value: 'Kid appropriate',
        },
        {
          label: 'teenagers',
          value: 'Teenager friendly',
        },
        {
          label: 'adultAndSensitive',
          value: 'No Adult Cotent',
        },
        {
          label: 'kids/teen/Adult',
          value: 'Content Access',
        },
      ],
    },
    web_category: {
      label: t('visualizations.webCategoryLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    device_category: {
      label: t('visualizations.kindCategoryLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.MULTI,
      items: [],
    },
    metric: {
      label: t('visualizations.metricLabel'),
      defaultLabel: t('visualizations.defaultLabel'),
      type: DropdownFilterType.SINGLE,
      items: [
        {
          label: 'Event',
          value: 'Event',
        },
        {
          label: 'Location',
          value: 'Location',
        },
        {
          label: 'Both',
          value: 'Both',
        },
      ],
    },
  };
}

function additionalFilterValues(t: TFunction) {
  return {
    tenant: filterDropdownGroupItems(t).tenant.items[0],
    tenant_type: filterDropdownGroupItems(t).tenant_type.items[0],
    time: filterDropdownGroupItems(t).time.items[1],
    provider: filterDropdownGroupItems(t).provider.items[0],
    profile: filterDropdownGroupItems(t).profile.items[0],
    metric: filterDropdownGroupItems(t).metric.items[0],
  };
}

export function contentAccessProtectionConfig(t: TFunction) {
  return {
    title: t('visualizations.contentAccessProtection'),
    mixPanelName: 'Content Access Protection',
    infoText: t('infoText.contentAccessProtection'),
    onlyShowRelevantCategoricalFilterValues: true,
    url: getTableauUrl(TableauName.contentAccessProtection),
    filterDropdownGroupItems: filterDropdownGroupItems(t),
    additionalFilterValues: additionalFilterValues(t),
    categoricalFilters: [
      'tenant',
      'web_category',
      'device_category',
      'deployment',
      'tenant_type',
    ],
    staticFilters: [],
    parameters: ['provider', 'profile', 'metric', 'time'],
  };
}
