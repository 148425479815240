import React, { FunctionComponent } from 'react';

type IconProps = JSX.IntrinsicElements['svg'];

export const CustomerMenuFilledIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5921_20643)">
        <path
          d="M10.1625 1.44375C10.1625 2.08578 9.64206 2.60625 9.00002 2.60625C8.35799 2.60625 7.83752 2.08578 7.83752 1.44375C7.83752 0.801719 8.35799 0.28125 9.00002 0.28125C9.64206 0.28125 10.1625 0.801719 10.1625 1.44375Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M16.5562 10.1625C15.9142 10.1625 15.3937 9.64206 15.3937 9.00002C15.3937 8.35799 15.9142 7.83752 16.5562 7.83752C17.1983 7.83752 17.7187 8.35799 17.7187 9.00002C17.7187 9.64206 17.1983 10.1625 16.5562 10.1625Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M13.5209 15.165C13.0669 14.711 13.0669 13.975 13.5209 13.521C13.9749 13.067 14.7109 13.067 15.1649 13.521C15.6189 13.975 15.6189 14.711 15.1649 15.165C14.7109 15.619 13.9749 15.619 13.5209 15.165Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M10.1625 16.5563C10.1625 17.1983 9.64206 17.7188 9.00002 17.7188C8.35799 17.7188 7.83752 17.1983 7.83752 16.5563C7.83752 15.9143 8.35799 15.3938 9.00002 15.3938C9.64206 15.3938 10.1625 15.9143 10.1625 16.5563Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M1.44376 10.1625C0.801731 10.1625 0.281262 9.64206 0.281262 9.00002C0.281262 8.35799 0.801731 7.83752 1.44376 7.83752C2.08579 7.83752 2.60626 8.35799 2.60626 9.00002C2.60626 9.64206 2.08579 10.1625 1.44376 10.1625Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M2.83475 4.47899C2.38076 4.02501 2.38076 3.28896 2.83475 2.83497C3.28873 2.38099 4.02479 2.38099 4.47877 2.83497C4.93275 3.28896 4.93275 4.02501 4.47877 4.47899C4.02479 4.93298 3.28873 4.93298 2.83475 4.47899Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M15.1645 4.47849C14.7106 4.93247 13.9745 4.93247 13.5205 4.47849C13.0665 4.0245 13.0665 3.28845 13.5205 2.83446C13.9745 2.38048 14.7106 2.38048 15.1645 2.83446C15.6185 3.28845 15.6185 4.0245 15.1645 4.47849Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M4.47893 15.1646C4.02495 15.6186 3.28889 15.6186 2.83491 15.1646C2.38093 14.7107 2.38093 13.9746 2.83491 13.5206C3.28889 13.0666 4.02495 13.0666 4.47893 13.5206C4.93292 13.9746 4.93292 14.7107 4.47893 15.1646Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <ellipse
          cx="9.00002"
          cy="7.25625"
          rx="1.1625"
          ry="1.1625"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M8.99999 9.5813C8.42049 9.5813 7.8905 9.79331 7.48337 10.144C6.64647 10.8649 7.57042 11.9063 8.67499 11.9063H9.32499C10.4296 11.9063 11.3535 10.8649 10.5166 10.144C10.1095 9.79331 9.57949 9.5813 8.99999 9.5813Z"
          fill="white"
          stroke="white"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_5921_20643">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const PerformanceMenuFilledIcon: FunctionComponent<IconProps> = (
  props,
) => {
  return (
    <svg
      viewBox="0 0 18 18"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_5921_20666)">
        <path
          d="M0.28125 9C0.28125 4.18477 4.18477 0.28125 9 0.28125C13.8152 0.28125 17.7188 4.18477 17.7188 9C17.7187 13.8152 13.8152 17.7188 9 17.7188C4.18477 17.7187 0.28125 13.8152 0.28125 9Z"
          fill="white"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M3.76874 10.7437C3.76874 9.7807 4.54944 9 5.51249 9C6.47553 9 7.25624 9.7807 7.25624 10.7438C7.25624 11.7068 6.47553 12.4875 5.51249 12.4875C4.54944 12.4875 3.76874 11.7068 3.76874 10.7437Z"
          fill="black"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M7.25623 5.51255C7.25623 4.5495 8.03693 3.7688 8.99998 3.7688C9.96302 3.7688 10.7437 4.5495 10.7437 5.51255C10.7437 6.4756 9.96302 7.2563 8.99998 7.2563C8.03693 7.2563 7.25623 6.4756 7.25623 5.51255Z"
          fill="black"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M10.7438 10.7437C10.7438 9.7807 11.5245 9 12.4875 9C13.4506 9 14.2313 9.7807 14.2313 10.7438C14.2313 11.7068 13.4506 12.4875 12.4875 12.4875C11.5245 12.4875 10.7438 11.7068 10.7438 10.7437Z"
          fill="black"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M9 7.25623V17.7187"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M12.4875 12.4875V17.1375"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
        <path
          d="M5.51251 12.4875V17.1375"
          stroke="black"
          stroke-width="0.8"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_5921_20666">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DevicesMenuFilledIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 18 18"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.10625 0.84375H0.84375V4.10625H4.10625V0.84375Z"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M17.1562 13.8938H13.8937V17.1563H17.1562V13.8938Z"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M4.10625 17.1563L2.475 13.8938L0.84375 17.1563H4.10625Z"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <ellipse
        cx="15.525"
        cy="2.475"
        rx="1.63125"
        ry="1.63125"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M5.19373 2.47498H12.8062"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M5.19373 15.525L12.8062 15.525"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M4.10626 13.8938L13.35 4.65005"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
    </svg>
  );
};
export const NodesMenuFilledIcon: FunctionComponent<IconProps> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="5.75625"
        cy="5.75625"
        r="1.63125"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <circle
        cx="11.9438"
        cy="18.4125"
        r="1.63125"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <circle
        cx="18.1313"
        cy="5.75625"
        r="1.63125"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <circle
        cx="11.9438"
        cy="11.3813"
        r="1.63125"
        fill="currentColor"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M7.5 7.5L10.3125 10.0312"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <path
        d="M16.35 7.65L13.6875 10.0312"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
      <line
        x1="11.9"
        y1="13.6875"
        x2="11.9"
        y2="16.2188"
        stroke="white"
        stroke-width="0.8"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export const FullStackOptimizationMenuFilledIcon: FunctionComponent<IconProps> = (
  props,
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.75"
        y="4.75"
        width="14.5"
        height="4.5"
        rx="2.25"
        stroke="#DEE0E2"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 14.3502C4 13.9912 4.29101 13.7002 4.65 13.7002H19.35C19.709 13.7002 20 13.9912 20 14.3502C20 14.7092 19.709 15.0002 19.35 15.0002H4.65C4.29101 15.0002 4 14.7092 4 14.3502Z"
        fill="#DEE0E2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 19.3502C4 18.9912 4.29101 18.7002 4.65 18.7002H19.35C19.709 18.7002 20 18.9912 20 19.3502C20 19.7092 19.709 20.0002 19.35 20.0002H4.65C4.29101 20.0002 4 19.7092 4 19.3502Z"
        fill="#DEE0E2"
      />
    </svg>
  );
};

export const FullStackOptimizationMenuIcon: FunctionComponent<IconProps> = (
  props,
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4.75"
        y="4.4502"
        width="14.5"
        height="4.5"
        rx="2.25"
        stroke="#8f9397"
        stroke-width="1.5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 14.3502C4 13.9912 4.29101 13.7002 4.65 13.7002H19.35C19.709 13.7002 20 13.9912 20 14.3502C20 14.7092 19.709 15.0002 19.35 15.0002H4.65C4.29101 15.0002 4 14.7092 4 14.3502Z"
        fill="#8f9397"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 19.65C4 19.291 4.29101 19 4.65 19H19.35C19.709 19 20 19.291 20 19.65C20 20.009 19.709 20.3 19.35 20.3H4.65C4.29101 20.3 4 20.009 4 19.65Z"
        fill="#8f9397"
      />
    </svg>
  );
};
