import React, { FunctionComponent } from 'react';
import { RecoilRoot } from 'recoil';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './AppContainer';

const App: FunctionComponent = () => {
  return (
    <RecoilRoot>
      <Router>
        <AppContainer />
      </Router>
    </RecoilRoot>
  );
};

export default App;
